import React from "react";
import Contianer from "../components/Contianer";
import SectionFooter from "../components/SectionFooter";

const About = () => {
  return (
    <Contianer>
      <section id="about">
        <div className="py-24 relative">
          <h2 className="section__h2">About Me</h2>
          <div className="grid gap-12">
            <div className="mt-10 space-y-5 xl:flex">
              <article className="space-y-5 xl:w-1/2">
                <Para>
                  Hi, I’m Ahmed a self-taught front-end developer with passion
                  to learn the web and building websites, I got a{" "}
                  <a
                    href="https://confirm.udacity.com/GJ2SXTEF"
                    target={"_blank"}
                    className="text-opacity-100 text-primary font-medium underline underline-offset-1 hover:text-secoundary"
                    rel="noreferrer"
                  >
                    professional front-end developer certification
                  </a>{" "}
                  from Udacity. And currently working as freelancer.
                </Para>
                <Para>
                  I am well-organized, efficient person and I love learning new
                  things every day and I enjoy coding, gaming, and watching tv
                  series.
                </Para>
              </article>
              <div className="w-fit xl:ml-28">
                <h4 className="text-lg tablet:text-xl md:text-2xl font-medium">
                  Technology I know:
                </h4>
                <ul className="pl-5 mt-3 list-inside list-disc space-y-3 font-medium text-base tablet:text-lg md:text-xl">
                  <li>HTML</li>
                  <li>CSS, SASS</li>
                  <li>TailwindCSS, Bootstrap</li>
                  <li>JS (ES5, ES6)</li>
                  <li>React JS</li>
                  <li>Git, Github</li>
                </ul>
              </div>
            </div>
            <div className="xl:w-2/3">
              <h4 className="text-xl tablet:text-2xl md:text-3xl font-semibold mb-8">
                What I do
              </h4>
              <div className="space-y-5">
                <Para>
                  I take designers’ design and bring it to life using, building
                  the UI and make sure it run properly and give the sites’ users
                  have great experiences.
                </Para>
                <Para>
                  I care about responsive websites and accessibility using
                  semantic html.
                </Para>
                <Para>
                  <span className="font-semibold text-primary">
                    Check what I can do in my projects
                  </span>
                </Para>
              </div>
            </div>
          </div>
          <SectionFooter text="ever positive, never negative" />
        </div>
      </section>
    </Contianer>
  );
};
interface ParaProps {
  children?: (string | JSX.Element)[] | string | JSX.Element;
}
const Para = ({ children }: ParaProps) => {
  return (
    <p className="text-lg tablet:text-xl md:text-2xl text-primary text-opacity-85">
      {children}
    </p>
  );
};

export default About;
