import React from "react";
import { motion } from "framer-motion";
import Contianer from "../components/Contianer";
import SectionFooter from "../components/SectionFooter";
import Header from "./Header";
// images
import pic from "../assests/images/pic.jpg"
import arrow from "../assests/images/icons/arrow.svg";
import circle from "../assests/images/icons/circle.svg";
// animation
import { divAnim, slideB, fade } from "../animation";

const Hero = () => {
  return (
    <Contianer>
      <div className="h-screen flex flex-col">
        <Header />
        <section id="hero" className="flex-1 relative">
          <motion.article
            variants={divAnim}
            initial="hidden"
            animate="show"
            className="mt-[15vh]"
          >
            <div className="overflow-hidden">
              <motion.h1
                variants={slideB}
                className="text-base mb-1 tablet:text-lg font-TenorSans"
              >
                Hi, My name is
              </motion.h1>
            </div>
            <div className="overflow-hidden">
              <motion.h2
                variants={slideB}
                className="text-3xl font-bold mb-2 tablet:text-5xl font-TenorSans"
              >
                Ahmed Mahmoud
              </motion.h2>
            </div>
            <div className="overflow-hidden">
              <motion.h3
                variants={slideB}
                className="text-2xl mb-4 tablet:text-4xl font-TenorSans"
              >
                I build things for the web
              </motion.h3>
            </div>
            <motion.p
              variants={fade}
              className="text-base tablet:text-lg lg:text-xl font-normal tablet:w-2/3 lg:w-1/2 text-primary text-opacity-75"
            >
              I'm a front-end developer specializing in building responsive,
              easy-to-use websites for consumers.
            </motion.p>
          </motion.article>
          <div className="w-64 h-64 absolute top-0 -right-16 -z-10 tablet:-top-16 tablet:w-96 tablet:h-96 md:w-[30rem] md:h-[30rem] select-none">
            <img className="mix-blend-multiply" src={circle} alt="circle" />
          </div>
          <div className="w-64 h-64 absolute -bottom-16 -left-16 -z-10 tablet:w-96 tablet:h-96 tablet:-left-22 tablet:-bottom-24 md:w-[30rem] md:h-[30rem] select-none">
            <img className="mix-blend-multiply" src={circle} alt="circle" />
          </div>
          <div className="absolute left-0 bottom-12 rotate-90 w-[10vh] max-w-[7rem] select-none">
            <motion.img
              variants={fade}
              initial="hidden"
              animate="show"
              transition={{ duration: 1 }}
              className="w-full"
              src={arrow}
              alt="move-down"
            />
          </div>
          <SectionFooter text="Do what you love" />
        </section>
      </div>
    </Contianer>
  );
};

export default Hero;
