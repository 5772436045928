import React from "react";
import {motion} from "framer-motion"
// animation
import {slideB} from "../animation"
interface props {
  text: string;
}

const SectionFooter = ({ text }: props) => {
  return (
    <div className="absolute w-full bottom-4 right-0 font-TenorSans font-bold">
        <div className="flex flex-col items-end">
          <div className="overflow-hidden w-2/3">
            <motion.p variants={slideB} initial="hidden" animate="show" className=" uppercase text-xs md:text-sm text-primary text-opacity-75 text-right">
              ”{text}„
            </motion.p>
          </div>
          <div className="w-2/3 h-px bg-primary"></div>
        </div>
    </div>
  );
};

export default SectionFooter;
