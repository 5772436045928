import React, { useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import circle from "../assests/images/icons/circle.svg";
// animation
import { slideB, mobileMenuAnim } from "../animation";

interface HamburgerProps {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isOpen: boolean;
}
interface MenuProps {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}
const MobileMenu = () => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    isOpen?  document.body.classList.add("scroll-lock") : document.body.classList.remove("scroll-lock")
  }, [isOpen]);
  return (
    <>
      <Hamburger setIsOpen={setIsOpen} isOpen={isOpen} />
      <AnimatePresence>
        {isOpen ? <Menu setIsOpen={setIsOpen} /> : null}
      </AnimatePresence>
    </>
  );
};

const Menu = ({ setIsOpen }: MenuProps) => {
  function clickHandler(e: React.MouseEvent<HTMLButtonElement>) {
    setIsOpen(false);
    document
      .getElementById(e.currentTarget.value)
      ?.scrollIntoView({ behavior: "smooth", block: "start" });
  }
  return (
    <motion.div
      className="absolute w-screen h-screen bg-bg left-0 top-0 z-40 pt-[20vh] px-4 flex flex-col justify-between"
      variants={mobileMenuAnim}
      initial="hidden"
      animate="show"
      exit={{opacity: 0, transition: {
        duration: 0.1
      }}}
    >
      <div className="w-64 h-64 absolute top-16 -right-16 -z-10 tablet:w-80 tablet:h-80">
        <img className="mix-blend-multiply" src={circle} alt="circle" />
      </div>
      <div className="w-64 h-64 absolute bottom-1 right-56 -z-10 tablet:w-80 tablet:h-80 tablet:right-80 tablet:-bottom-16">
        <img className="mix-blend-multiply" src={circle} alt="circle" />
      </div>
      <div className="flex flex-col items-end space-y-14 ">
        <motion.button
          variants={slideB}
          className="mobile-menu_link underline_link"
          value={"about"}
          onClick={clickHandler}
        >
          About me
        </motion.button>
        <motion.button
          variants={slideB}
          className="mobile-menu_link underline_link"
          value={"projects"}
          onClick={clickHandler}
        >
          Projects
        </motion.button>
        <motion.button
          variants={slideB}
          className="mobile-menu_link underline_link"
          value={"cv"}
          onClick={clickHandler}
        >
          CV
        </motion.button>
        <motion.button
          variants={slideB}
          className="mobile-menu_link underline_link"
          value={"contact"}
          onClick={clickHandler}
        >
          Contact
        </motion.button>
      </div>
      <div className="flex justify-between items-center mb-5">
        <motion.a
          variants={slideB}
          className="text-2xl underline font-TenorSans"
          href="https://github.com/Ahmed102952/"
        >
          Github
        </motion.a>
        <motion.a
          variants={slideB}
          className="text-2xl underline font-TenorSans"
          href="https://www.linkedin.com/in/ahmed-mahmoud-034132220/"
        >
          Linkedin
        </motion.a>
        <motion.a
          variants={slideB}
          className="text-2xl underline font-TenorSans"
          href="https://www.upwork.com/freelancers/~01200d68b568fb4a4f"
        >
          Upwork
        </motion.a>
      </div>
    </motion.div>
  );
};
const Hamburger = ({ setIsOpen, isOpen }: HamburgerProps) => {
  const clickHandler = () => {
    setIsOpen(!isOpen);
  };
  return (
    <button
      className="w-11 h-11 flex flex-col items-center justify-center space-y-[4px] z-50 relative"
      onClick={clickHandler}
    >
      <span className="w-7 h-[2px] bg-primary"></span>
      <span className="w-7 h-[2px] bg-primary"></span>
      <span className="w-7 h-[2px] bg-primary"></span>
    </button>
  );
};

export default MobileMenu;
