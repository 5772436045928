import { motion } from "framer-motion";
import React from "react";
import Contianer from "../components/Contianer";
import MobileMenu from "../components/MobileMenu";
import Nav from "../components/Nav";

const Header = () => {
  return (
      <header>
        <div className="py-5 tablet:py-12 flex justify-between items-end">
          <motion.h2
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.3, ease: "easeInOut" }}
            className="text-2xl tablet:text-4xl lg:text-5xl font-TenorSans font-bold text-primary"
            translate="no"
          >
            am.
          </motion.h2>
          <div className="hidden md:block">
            <Nav />
          </div>
          <div className="block md:hidden">
            <MobileMenu />
          </div>
        </div>
      </header>
  );
};

export default Header;
