import { motion } from "framer-motion";
import React from "react";
// animation
import { slideB, navAnim } from "../animation";

const Nav = () => {
  function clickHandler(e: React.MouseEvent<HTMLButtonElement>) {
    document
      .getElementById(e.currentTarget.value)
      ?.scrollIntoView({ behavior: "smooth" });
  }
  return (
    <motion.nav
      variants={navAnim}
      initial="hidden"
      animate="show"
      transition={{ ease: "easeInOut" }}
    >
      <ul className="flex space-x-14 overflow-hidden">
        <motion.li variants={slideB}>
          <button
            value={"about"}
            className="text-primary text-lg underline_link"
            onClick={clickHandler}
          >
            About me
          </button>
        </motion.li>
        <motion.li variants={slideB}>
          <button
            value={"projects"}
            className="text-primary text-lg underline_link"
            onClick={clickHandler}
          >
            Projects
          </button>
        </motion.li>
        <motion.li variants={slideB}>
          <button
            value={"cv"}
            className="text-primary text-lg underline_link"
            onClick={clickHandler}
          >
            CV
          </button>
        </motion.li>
        <motion.li variants={slideB}>
          <button
            value={"contact"}
            className="text-primary text-lg underline_link"
            onClick={clickHandler}
          >
            Contact
          </button>
        </motion.li>
      </ul>
    </motion.nav>
  );
};

export default Nav;
