import React from "react";
import About from "../Layout/About";
import Hero from "../Layout/Hero";
import Projects from "../Layout/Projects";

const Home = () => {
  return (
    <>
      <Hero />
      <About />
      <Projects />
    </>
  );
};

export default Home;
