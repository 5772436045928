import React from "react";
import theweatherPreview from "../assests/images/preview/theweather-preview.png";
import managePreview from "../assests/images/preview/manage-preview.png";
import crowdfundPreview from "../assests/images/preview/crowdfund-preview.png";
import easybankPreview from "../assests/images/preview/easybank-preview.png";

const ProjectsGrid = () => {
  return (
    <div className="grid tablet:grid-cols-2 lg:grid-cols-3 gap-x-8 lg:gap-x-12 gap-y-16 py-14 mx-auto">
      {projectsData.map((p) => {
        return (
          <ProjectBox img={p.img} title={p.title} desc={p.desc} key={p.title} />
        );
      })}
    </div>
  );
};
interface projectBoxProps {
  img: string;
  title: string;
  desc: string;
}
const ProjectBox = ({ img, title, desc }: projectBoxProps) => {
  return (
    <button>
      <div className="space-y-4 tablet:space-y-6 text-start">
        <div className="rounded-lg overflow-hidden shadow-md">
          <img className="" src={img} alt={title} loading="lazy" />
        </div>
        <div>
          <h3 className="text-lg tablet:text-xl md:text=2xl font-medium">
            "{title}"
          </h3>
          <p className="text-lg tablet:text-xl md:text=2xl">- {desc}</p>
        </div>
      </div>
    </button>
  );
};

const projectsData = [
  { img: theweatherPreview, title: "The Weather", desc: "Bref Weather App" },
  { img: managePreview, title: "Manage", desc: "Landing Page" },
  {
    img: crowdfundPreview,
    title: "Crowdfund",
    desc: "Crowd Funding Product Page",
  },
  { img: easybankPreview, title: "Easybank", desc: "landing Page" },
];

export default ProjectsGrid;
