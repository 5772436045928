export const navAnim = {
  hidden: {
    opacity: 0,
  },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1,
      duration: 0.3,
      when: "beforeChildren",
    },
  },
};
export const slideB = {
  hidden: {
    opacity: 0,
    y: 50
  },
  show: {
    opacity: 1,
    y:0,
    transition: {
      duration: 0.3,
    }
  }
}
export const mobileMenuAnim = {
  hidden: {
    opacity: 0,
  },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1,
      duration: 0.3,
      when: "beforeChildren",
    },
  },
  exit: {
    opacity: 0,
    transtion: {
      duration: 0.1,
    },
  },
};
export const divAnim = {
  hidden: {
    opacity:0,
  },
  show: {
    opacity: 1,
    transition: {
      duration: 0.3,
      staggerChildren: 0.3,
      when: "beforeChildren"
    }
  }
}

export const fade = {
  hidden: {
    opacity: 0
  },
  show: {
    opacity: 1
  }
}