import React from "react";
import ProjectsGrid from "../components/ProjectsGrid";
import SectionFooter from "../components/SectionFooter";

const Projects = () => {
  return (
    <section id="projects">
      <div className="py-24 relative">
        <h2 className="relative text-3xl tablet:text-4xl md:text-5xl font-medium before:absolute before:w-[60%] before:h-px md:before:h-1 before:-bottom-10 mb-20 before:bg-primary">
          Projects
        </h2>
        <ProjectsGrid />
        <SectionFooter text="..." />
      </div>
    </section>
  );
};

export default Projects;
