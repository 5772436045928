import React from "react";

const Contianer = ({
  children,
}: {
  children?: JSX.Element | JSX.Element[];
}) => {
  return (
    <div className="px-4 tablet:px-10 w-full mx-auto max-w-4xl lg:max-w-4xl xl:max-w-6xl">
      {children}
    </div>
  );
};

export default Contianer;
